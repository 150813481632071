<template>
  <div>
    <div class="flex items-center justify-between w-56 mr-4 mt-4">
      <label class="w-16 font-semibold">{{ $t('week') }}</label>
      <t-select
        class="w-44"
        v-model="filterWeek"
        :options="weeks"
      ></t-select>
    </div>
    <table class="text-xs text-left w-full mt-4 border-collapse border border-gray-300">
      <thead class="bg-gray-300">
        <th class="w-1/3 p-2"> {{ $t('function') }}</th>
        <th class="w-1/5 p-2"> {{ $t('diff_people') }}</th>
        <th class="w-1/5 p-2"> {{ $t('from_total') }}</th>
      </thead>
      <tbody>
        <tr v-for="(stat, index) in employeeStats" :key="index"
          class="text-center">
          <td class="p-2 border border-gray-300"> {{ stat.job_name }} </td>
          <td class="p-2 border border-gray-300">{{ stat.distinct_total }}</td>
          <td class="p-2 border border-gray-300">{{ stat.total }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getEndWeekDuration, getWeekOptions } from "@/utils/misc";
import { actions, getters } from "@/constants/state";
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    this.getEmployees();
  },
  methods: {
    ...mapActions({
      getEmployeeStats: actions.STATS_FETCH_EMPLOYEES_ACTION,
      setMainFilterAction: actions.SESSION_SET_MAIN_FILTER_ACTION
    }),
    async getEmployees() {
      this.$emit('setLoading', true);
      try {
        await this.getEmployeeStats({
          from: this.filter.week,
          until: getEndWeekDuration(this.filter.week)
        })
      } catch (error) {
        console.error('DEBUG EmployeeList', error);
      }
      this.$emit('setLoading', false);
    },
  },
  computed: {
    ...mapGetters({
      filter: getters.SESSION_MAIN_FILTER_GETTER,
      employeeStats: getters.STATS_EMPLOYEES_GETTER,
    }),
    weeks() {
      return getWeekOptions(this.isAdminOrSuperUser);
    },
    filterWeek: {
      get() {
        return this.filter.week;
      },
      set(week) {
        this.setMainFilterAction({
          ...this.filter,
          week
        });
        this.getEmployees();
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
